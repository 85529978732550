/* Oprava zdvojení vnějšího okraje v plovoucím elementu pomocí display: inline */

.grid-2,
.grid-3,
.grid-4,
.grid-6,
.grid-9,
.grid-12,
.sidebar,
.content-main,
.games-gallery li,
.news-gallery li,
.members dl,
.about-gallery li	 { display:inline; }

/* Hover akce v IE6 */


body {
   behavior: url(../csshover3.htc);
}